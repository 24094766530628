<template>
  <div v-loading="loading" id="login-home">
    <div class="box-l">
      <div class="group-login">
        <img src="@/assets/logo-login.png" alt="EXAC logo" class="logo" />
        <div>
          <h1 class="font-40 font-sign-in">Sign in</h1>
          <el-form
            ref="form"
            label-position="top"
            label-width="100px"
            :model="form"
            :rules="rules"
          >
            <el-form-item label="Username" prop="username">
              <el-input
                v-model="form.username"
                @keyup.native.13="Login"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                v-model="form.password"
                type="password"
                @keyup.native.13="Login"
                show-password
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              class="font-16 w-100 btn-login"
              @click="Login"
              >Sign in</el-button
            >
            <p class="text-center font-16 mg-t-5 color-ff">
              Changelog : {{ toc }}
            </p>
          </el-form>
        </div>
      </div>
    </div>
    <div class="box-r"></div>
    <!-- <div class="pd-x-5">
      <el-row :gutter="30" class="is-flex js-center" style="margin-top: 10vh">
        <el-col :span="24" :md="12">
          <el-form
            ref="form"
            label-position="top"
            label-width="100px"
            :model="form"
            :rules="rules"
          >
            <el-form-item label="Username" prop="username">
              <el-input
                v-model="form.username"
                @keyup.native.13="Login"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                v-model="form.password"
                type="password"
                @keyup.native.13="Login"
              ></el-input>
            </el-form-item>
            <el-button type="primary" class="font-16 w-100" @click="Login"
              >Login</el-button
            >
          </el-form>
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import { makeTOC } from "@/service/utilLog";
import txt from "!!raw-loader!../../CHANGELOG.md";
export default {
  data() {
    return {
      toc: [],
      textFor: txt,
      tempPassword: "",
      loading: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "กรุณากรอกยูสเซอร์เนม",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
        ],
      },
      sPhrase: process.env.VUE_APP_SECRET_KEY,
    };
  },
  mounted() {
    this.cutWord();
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      pageActive: (state) => state.pageActive,
    }),
  },
  methods: {
    cutWord() {
      let txtLength = this.textFor.length;
      let txtData = this.textFor.toString();
      let subData = this.textFor.toString();
      let allData = [];
      let allSub = [];
      for (var i = 0; i < txtLength; i++) {
        let anchor = txtData.match(/[#]+[#] [[]+[\S]+/);
        if (anchor != null) {
          allData.push(anchor[0]);
          var res = txtData.replace(anchor[0], "");
          txtData = res;
        }
      }
      for (var i = 0; i < txtLength; i++) {
        let anchorSub = subData.match(/[#]+[#]+[#]+[ \w.\d]+/);
        if (anchorSub != null) {
          allSub.push(anchorSub[0]);
          var res = subData.replace(anchorSub[0], "");
          subData = res;
        }
      }
      let obj = makeTOC(allData, allSub);
      this.toc = obj.toc[0].label;
    },
    async apiLogin() {
      // if (this.tempPassword == "") {
      this.tempPassword = this.CryptoJS.AES.encrypt(
        this.form.password,
        this.sPhrase
      ).toString();
      // }
      let obj = {
        username: this.form.username,
        password: this.tempPassword,
      };
      let pathLoginByENV = process.env.VUE_APP_PATH_LOGIN;
      let respone = await HTTP.post(pathLoginByENV, obj)
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("SET_USER", res.data.obj);
            this.$store.commit("SET_TOKEN", res.data.obj.token);
            this.gotoInside();
            this.loading = true;
            this.$message({
              message: "เข้าสู่ระบบสำเร็จ",
              type: "success",
              duration: 4000,
            });
            return res;
          } else {
            if (res.data.status == 401) {
              if (res.data.data == "The email or password is incorrect.") {
                this.$message({
                  message: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
                return obj;
              } else {
                this.$message({
                  message: res.data.data,
                  type: "error",
                  duration: 4000,
                });
                return "0.2";
              }
            } else {
              this.$message({
                message: res.data.data,
                type: "error",
                duration: 4000,
              });
              return "0.3";
            }
          }
        })
        .catch((e) => {
          console.log('\n\nerr: ', e);
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              if (e.response.data.data == "Lap Error") {
                let dataMes = e.response.data.obj.lde_message;
                let myArray = dataMes.split(",");
                let code = myArray[2].split(" ");
                let ReMessage = "";
                switch (code[code.length - 1]) {
                  case "525":
                    ReMessage = "User not found";
                    break;
                  case "52e":
                    ReMessage = "Invalid credentials";
                    break;
                  case "530":
                    ReMessage = "Not permitted to logon at this time";
                    break;
                  case "532":
                    ReMessage = "Password expired";
                    break;
                  case "533":
                    ReMessage = "Account disabled";
                    break;
                  case "701":
                    ReMessage = "Account expired";
                    break;
                  case "773":
                    ReMessage = "User must reset password";
                    break;
                  case "775":
                    ReMessage = "Account locked";
                    break;
                  default:
                    break;
                }
                this.$message({
                  message: ReMessage,
                  type: "error",
                  duration: 4000,
                });
              } else {
                this.$message({
                  message: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }

              // this.checkAuth();
            } else if (e.response.data.status == 403) {
              this.$message({
                message: "คุณไม่มีสิทธิเข้าถึง",
                type: "error",
                duration: 4000,
              });
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else if (e.response.data.status == 422) {
              if (e.response.data.obj != null && e.response.data.obj != null) {
                if (
                  e.response.data.obj.message == "forbidden word" ||
                  e.response.data.obj.message == "invalid password" ||
                  e.response.data.obj.message ==
                    `Please check the mark " ", ), (`
                ) {
                  this.$message({
                    message: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง",
                    type: "error",
                    duration: 4000,
                  });
                }
              } else {
                this.alertCatchError(e);
              }
            } else {
              this.alertCatchError(e);
            }
          }
          return e;
        });
      return respone;
    },
    Login() {
      this.$refs["form"].validate().then((valid) => {
        if (valid) {
          this.apiLogin();
        } else {
          return false;
        }
      });
    },
    gotoInside() {
      let custom = this.$store.state.user.custom || 0;
      let permissionCustom = [];
      if (permissionCustom != undefined && permissionCustom != null) {
        permissionCustom = this.$store.state.user.permissionCustom;
      }
      let role = this.$store.state.user.role || null;
      let userLevel = this.$store.state.user.role.roleLevel;
      // validation follow role
      if (userLevel > 40) {
        if (userLevel == 90 || userLevel == 80) {
          this.$router.push(`/user`);
        } else if (userLevel == 70) {
          this.$router.push(`/manage/credit`);
        } else if (userLevel == 60) {
          this.$router.push(`/manage/export-insurance`);
        } else if (userLevel == 50) {
          this.$router.push(`/manage-admin/add-role`);
        } else {
          this.$router.push(`/dashboard`);
        }
      } else {
        // new role iss not level
        var permission = [];
        if (custom == 1) {
          permission = permissionCustom;
          permission = permission.map((e) => ({
            ...e,
            status: e.status == true ? 1 : 3,
          }));
        } else {
          if (role.permission != undefined && role.permission != null) {
            permission = role.permission;
          }
        }

        permission = permission
          .filter((e) => e.status != 3)
          .sort((a, b) => a.pemNum - b.pemNum);

        if (permission.length > 0) {
          if (permission[0].permissionName == "จัดการข้อมูลผู้ประกอบการ") {
            this.$router.push("/user");
          } else if (
            permission[0].permissionName == "เกณฑ์พิจารณาจัดเกรดลูกค้า"
          ) {
            this.$router.push(`/criteria-credit`);
          } else if (permission[0].permissionName == "สิทธิ์ผู้ดูแลระบบ") {
            this.$router.push(`/manage-admin/add-role`);
          } else if (permission[0].permissionName == "ผู้ใช้งาน") {
            this.$router.push(`/manage-admin/add-admin`);
          } else if (permission[0].permissionName == "จัดการสิทธิ์ผู้ใช้งาน") {
            this.$router.push(`/manage-admin/manage-role`);
          } else if (
            permission[0].permissionName == "เปลี่ยนแปลงสิทธิ์ผู้ใช้งาน"
          ) {
            this.$router.push(`/manage-admin/manage-role`);
          } else if (permission[0].permissionName == "ตั้งค่าสถานะใน CFOE") {
            this.$router.push(`/manage-admin/manage-role`);
          } else if (permission[0].permissionName == "ข้อมูลส่วนตัว") {
            this.$router.push(`/user`);
          } else if (permission[0].permissionName == "ข้อมูลบริษัท") {
            this.$router.push(`/user`);
          } else if (
            permission[0].permissionName == "Download Financial Projection"
          ) {
            this.$router.push(`/user`);
          } else if (permission[0].permissionName == "Export") {
            this.$router.push(`/user`);
          } else if (permission[0].permissionName == "จัดการ/ค้นหาสินเชื่อ") {
            this.$router.push(`/manage/credit`);
          } else if (permission[0].permissionName == "จัดการ/ค้นหาประกัน") {
            this.$router.push(`/manage/export-insurance`);
          } else if (permission[0].permissionName == "จัดการ/ค้นหาโครงการ") {
            this.router.push(`/manage/project`);
          } else if (permission[0].permissionName == "แสดงสินเชื่อ") {
            this.$router.push(`/manage/credit`);
          } else if (permission[0].permissionName == "แสดงประกัน") {
            this.$router.push(`/manage/export-insurance`);
          } else if (permission[0].permissionName == "แสดงโครงการ") {
            this.router.push(`/manage/project`);
          } else if (permission[0].permissionName == "แสดงรายละเอียดสินเชื่อ") {
            this.$router.push(`/manage/credit`);
          } else if (permission[0].permissionName == "แสดงรายละเอียดประกัน") {
            this.$router.push(`/manage/export-insurance`);
          } else if (permission[0].permissionName == "แสดงรายละเอียดโครงการ") {
            this.router.push(`/manage/project`);
          } else if (permission[0].permissionName == "ภาพรวมทั้งหมด") {
            this.$router.push(`/dashboard`);
          } else if (permission[0].permissionName == "ภาพรวมสินเชื่อ") {
            this.$router.push(`/dashboard-credit`);
          } else if (permission[0].permissionName == "ภาพรวมประกันการส่งออก") {
            this.$router.push(`/dashboard-insurance`);
          } else if (permission[0].permissionName == "ภาพรวมโครงการ") {
            this.$router.push(`/dashboard-project`);
          } else if (permission[0].permissionName == "Import/Export ข้อมูล") {
            this.$router.push(`/import/export`);
          } else if (permission[0].permissionName == "History Logs") {
            this.$router.push(`/transaction-log`);
          } else if (permission[0].permissionName == "ข้อมูลผลิตภัณฑ์ที่สนใจ") {
            this.$router.push(`/user`);
          } else if (permission[0].permissionName == "Lead Scoring") {
            this.$router.push(`/lead-scoring`);
          } else {
            this.$router.push("/not-allowed");
          }
        } else {
          this.$router.push("/not-allowed");
        }
      }
    },
  },
};
</script>
